//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Filters from './FinanceBalancesReportsFilters.vue';

import tableConfig from './tableConfig';
import constant from '~/const';

export default {
  components: { Filters },

  data() {
    return {
      tableConfig,
      constant,
      selection: [],
      loader: false,
      loadingId: 0,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('financeBalancesReports', ['page', 'pages', 'data', 'sort']),
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader', 'setGeneralProgress', 'setSuccessNotification']),
    ...mapActions('financeBalancesReports', {
      loadDataAction: 'loadData',
      getReportAction: 'getReport',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    async downloadReport({ type, id, file }) {
      this.loadingId = id;
      const { data } = await this.getReportAction({
        reportType: type,
        reportId: id,
      });
      this.$utils.downloadReport(data, file);
      this.loadingId = 0;
      this.setSuccessNotification('Report downloaded');
    },

    isButtonLoading(id) {
      const { loadingId } = this;
      return loadingId === id;
    },
  },
};
