export default [
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Currency',
    },
    cell: {
      type: 'text',
      value: (e) => e.currencyTitle,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Balance',
    },
    cell: {
      type: 'slot',
      name: 'balance',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Available',
    },
    cell: {
      type: 'slot',
      name: 'available',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Frozen',
    },
    cell: {
      type: 'slot',
      name: 'frozen',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Referral balance',
    },
    cell: {
      type: 'slot',
      name: 'referralBalance',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Staking balance',
    },
    cell: {
      type: 'slot',
      name: 'stakingBalance',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Staking',
    },
    cell: {
      type: 'slot',
      name: 'staking',
    },
  },
];
