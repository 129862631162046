//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import UserBalances from '~/views/FinanceBalances/FinanceBalancesUserBalances/FinanceBalancesUserBalances.vue';
import Reports from '~/views/FinanceBalances/FinanceBalancesReports/FinanceBalancesReports.vue';

export default {
  components: {
    UserBalances,
    Reports,
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.loadFinanceBalancesReports(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('financeBalancesUserBalances', {
      loadFinanceBalancesUserBalances: 'loadData',
    }),
    ...mapActions('financeBalancesReports', {
      loadFinanceBalancesReports: 'loadData',
    }),
  },
};
