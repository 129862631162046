//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import UserSelect from '~/components/UserSelect.vue';

export default {
  components: {
    UserSelect,
  },

  props: {
    value: { type: Boolean, default: false },
    routerId: { type: String, default: () => '' },
  },

  data: () => ({
    amount: null,
    userId: '',
    currencyId: null,
    comment: '',
    details: '',
  }),

  computed: {
    ...mapGetters('currencies', ['getCurrencyById']),
    ...mapState('currencies', ['currencies']),
    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    ...mapActions('userBalanceBalances', {
      addBalanceAction: 'addBalance',
    }),
    ...mapActions('common', [
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
      'setGeneralProgress',
    ]),

    required(v) {
      return !!v || this.$t('This field is required');
    },

    addBalance() {
      const {
        amount,
        userId,
        currencyId,
        comment,
        details,
        routerId,
      } = this;

      if (currencyId && amount && routerId.length > 0 && details.length > 0) {
        this.confirmAction({
          title: 'Are you sure want to add balance to user?',
          hasCancelButton: true,
          callback: async () => {
            try {
              this.setGeneralProgress(true);
              await this.addBalanceAction({
                amount,
                currencyId,
                comment,
                details,
                userId: !routerId ? userId : routerId,
              });
              this.setGeneralProgress(false);
              this.setSuccessNotification('Balance successfully sent');
              this.closeAddBalanceModal();
            } catch (error) {
              this.setErrorNotification(error);
            }
          },
        });
      } else {
        this.setErrorNotification('Required fields are empty!');
      }
    },

    closeAddBalanceModal() {
      this.userId = null;
      this.amount = null;
      this.currencyId = null;
      this.comment = '';
      this.details = '';
      this.$emit('close');
    },
  },
};
