//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import constant from '~/const';

export default {
  props: {
    className: { type: String, default: '' },
    close: { type: Boolean, default: false },
    color: { type: String, default: '' },
    quantity: { type: Number, default: null },
    isSelected: { type: Boolean, default: false },
  },

  computed: {
    getColor() {
      const { DEFAULT_USER_TAG_COLOR } = constant.users;
      const { color } = this;

      if (!color) {
        return DEFAULT_USER_TAG_COLOR;
      }
      return color;
    },
  },

  methods: {
    closeHandler() {
      this.$emit('remove');
    },

    click() {
      this.$emit('click');
    },
  },
};
