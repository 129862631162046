//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import tableConfig from './tableExtraConfig';
import constant from '~/const';

export default {
  props: {
    data: { type: Object, required: true },
  },

  data() {
    return {
      loading: false,
      editingStaking: {
        modal: false,
        input: {
          perc: '',
          comment: '',
          fix: false,
          currencyId: 0,
        },
      },
      tableConfig,
    };
  },

  computed: {
    ...mapState('currencies', ['currencies']),

    items() {
      const { balanceInfo } = this.data;
      if (!balanceInfo) return [];

      const { items } = balanceInfo;
      return items;
    },

    isEditStakingDisabled() {
      const { perc, comment } = this.editingStaking.input;
      return (perc === '' || comment === '');
    },
  },

  async created() {
    const { userId } = this.data;
    this.loading = true;
    this.setGeneralProgress(true);
    await this.loadBalanceInfoAction(userId);
    this.loading = false;
    this.setGeneralProgress(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralProgress', 'confirmAction']),

    ...mapActions('financeBalancesUserBalances', {
      loadBalanceInfoAction: 'loadBalanceInfo',
    }),

    openEditStakingModal(row) {
      this.editingStaking.modal = true;
      this.editingStaking.input.perc = row.stakingPerc;
      this.editingStaking.input.fix = row.stakingPercFix;
      this.editingStaking.input.comment = '';
      this.editingStaking.input.currencyId = row.currencyId;
    },

    editStaking() {
      const { input } = this.editingStaking;
      this.confirmAction({
        text: 'Are you sure you want to update staking percent?',
        callback: () => {
          this.$emit('edit', {
            ...input,
            userIdList: [this.data.balanceInfo.userId],
          });
        },
      });
    },

    getTradingBalance(row) {
      const { TRADING } = constant.balance.BALANCE_TYPE;
      return row.balances.find((e) => e.balanceType === TRADING);
    },

    getReferralBalance(row) {
      const { REFERRAL } = constant.balance.BALANCE_TYPE;
      return row.balances.find((e) => e.balanceType === REFERRAL);
    },

    getStakingBalance(row) {
      const { STAKING } = constant.balance.BALANCE_TYPE;
      return row.balances.find((e) => e.balanceType === STAKING);
    },
  },
};
