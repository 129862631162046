var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-4 pb-4 extra"},[_c('UiTable',{attrs:{"data":_vm.items,"config":_vm.tableConfig,"is-loading":_vm.loading,"per-page":_vm.currencies.length},scopedSlots:_vm._u([{key:"balance",fn:function(ref){
var row = ref.row;
return [(_vm.getTradingBalance(row))?_c('div',[_vm._v(" "+_vm._s(_vm.getTradingBalance(row).availableFunds + _vm.getTradingBalance(row).frozenFunds)+" "+_vm._s(row.currencyTitle)+" ")]):_vm._e(),(_vm.getTradingBalance(row))?_c('div',{staticClass:"text--disabled"},[_vm._v(" ≈"+_vm._s(_vm.getTradingBalance(row).cumulativeAvailableFunds + _vm.getTradingBalance(row).cumulativeFrozenFunds)+" "+_vm._s(_vm.data.balanceInfo.cumulativeCurrencyTitle)+" ")]):_vm._e()]}},{key:"available",fn:function(ref){
var row = ref.row;
return [(_vm.getTradingBalance(row))?[_vm._v(" "+_vm._s(_vm.getTradingBalance(row).availableFunds)+" "+_vm._s(row.currencyTitle)+" ")]:_vm._e()]}},{key:"frozen",fn:function(ref){
var row = ref.row;
return [(_vm.getTradingBalance(row))?[_vm._v(" "+_vm._s(_vm.getTradingBalance(row).frozenFunds)+" "+_vm._s(row.currencyTitle)+" ")]:_vm._e()]}},{key:"referralBalance",fn:function(ref){
var row = ref.row;
return [(_vm.getReferralBalance(row))?[_vm._v(" "+_vm._s(_vm.getReferralBalance(row).availableFunds + _vm.getReferralBalance(row).frozenFunds)+" "+_vm._s(row.currencyTitle)+" ")]:_vm._e()]}},{key:"stakingBalance",fn:function(ref){
var row = ref.row;
return [(_vm.getStakingBalance(row))?[_vm._v(" "+_vm._s(_vm.getStakingBalance(row).availableFunds + _vm.getStakingBalance(row).frozenFunds)+" "+_vm._s(row.currencyTitle)+" ")]:_vm._e()]}},{key:"staking",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',[_vm._v(_vm._s(row.stakingPerc))]),_c('div',{staticClass:"ml-2"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openEditStakingModal(row)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1),_c('v-spacer'),(row.stakingPercFix)?_c('div',{staticClass:"overline"},[_vm._v(" "+_vm._s(_vm.$t('Fixed'))+" ")]):_vm._e()],1)]}}])}),_c('v-dialog',{attrs:{"max-width":"380"},model:{value:(_vm.editingStaking.modal),callback:function ($$v) {_vm.$set(_vm.editingStaking, "modal", $$v)},expression:"editingStaking.modal"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline mb-6"},[_vm._v(" "+_vm._s(_vm.$t('Update staking percent'))+" ")]),_c('v-card-text',[_c('v-switch',{attrs:{"inset":"","label":_vm.$t('Fix percent')},model:{value:(_vm.editingStaking.input.fix),callback:function ($$v) {_vm.$set(_vm.editingStaking.input, "fix", $$v)},expression:"editingStaking.input.fix"}}),_c('v-text-field',{attrs:{"dense":"","outlined":"","placeholder":" ","label":_vm.$t('Percent'),"disabled":!_vm.editingStaking.input.fix},model:{value:(_vm.editingStaking.input.perc),callback:function ($$v) {_vm.$set(_vm.editingStaking.input, "perc", $$v)},expression:"editingStaking.input.perc"}}),_c('v-textarea',{attrs:{"outlined":"","placeholder":" ","label":_vm.$t('Comment')},model:{value:(_vm.editingStaking.input.comment),callback:function ($$v) {_vm.$set(_vm.editingStaking.input, "comment", $$v)},expression:"editingStaking.input.comment"}}),_c('v-btn',{attrs:{"color":"success","height":"40px","outlined":"","disabled":_vm.isEditStakingDisabled},on:{"click":_vm.editStaking}},[_vm._v(" "+_vm._s(_vm.$t('Apply'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }