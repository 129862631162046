export default [
  {
    width: 18,
    header: {
      type: 'text',
      caption: 'User',
      sort: 'userName',
    },
    cell: {
      type: 'slot',
      name: 'user',
    },
  },
  {
    width: 15,
    header: {
      type: 'slot',
      name: 'balanceTitle',
      sort: 'cumulativeBalance',
    },
    cell: {
      type: 'slot',
      name: 'balance',
    },
  },
  {
    width: 18,
    header: {
      type: 'text',
      caption: 'Referrer',
    },
    cell: {
      type: 'slot',
      name: 'referrer',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Tags',
    },
    cell: {
      type: 'slot',
      name: 'tags',
    },
  },
  {
    width: 34,
    header: {
      type: 'text',
      caption: 'Comment',
    },
    cell: {
      type: 'slot',
      name: 'comment',
    },
  },
];
